<template>
  <div class="container">
    <edit-header
      entity="article"
      :error.sync="error"
      :invalid.sync="invalid"
      :invalid-message="invalidMessage"
      :submitted.sync="submitted"
      :isNew="isNew">
      <template v-slot:options>
        <div v-if="!isNew" class="d-flex align-items-center">
          <div v-if="ArticleStatus[item.status]">
            <i
              class="fe fe-circle rounded-circle mr-1"
              :class="ArticleStatus[item.status].class"></i>{{ArticleStatus[item.status].name}}
          </div>
          <div v-else>
            <i class="fe fe-circle rounded-circle b mr-1 bg-primary text-primary"></i>{{upperFirst(item.status)}}
          </div>
          <log-button-table
            v-if="item.id && item.modelName"
            v-model="item"
            class="ml-2"></log-button-table>
        </div>
      </template>
    </edit-header>

    <form
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <accordion
        custom-class="material"
        :option.sync="tabs.material"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('material')" class="fe fe-alert-circle text-danger mr-1"></i> Material type
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('material')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('material')">Edit</a>
        </template>
        <material-type-form
          v-if="item.id || isNew"
          ref="material"
          :value.sync="item"
          :is-packable="isPackable"
          :edit.sync="tabs.material.edit"
          :isGNFR="isGNFR"
          :disableIngredient="disableIngredient"
          @valid="nextStep('material')"></material-type-form>
      </accordion>

      <accordion
        custom-class="general"
        :option.sync="tabs.general"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('general')" class="fe fe-alert-circle text-danger mr-1"></i> General
          <i v-b-tooltip="GENERAL" class="fe fe-info"></i>
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('general')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('general')">Edit</a>
        </template>
        <general-form
          ref="general"
          :value.sync="item"
          :old="old"
          :uom="uom"
          :edit.sync="tabs.general.edit"
          :errors.sync="response.error"
          :show-checkbox="isAvailableForIgnoringQuantity()"
          @valid="nextStep('general')"
          @update-names="updateArticleNames">
        </general-form>
      </accordion>

      <accordion
        custom-class="replenishments"
        :option.sync="tabs.replenishment"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('replenishment')" class="fe fe-alert-circle text-danger mr-1"></i> Replenishment
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('replenishment')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('replenishment')">Edit</a>
        </template>
        <replenishment-form
          ref="replenishment"
          :value.sync="item"
          :edit.sync="tabs.replenishment.edit"
          :errors.sync="response.error"
          :old="response.old"
          :uom="uom"
          :price-change-request="can(uiPermissions.ARTICLES_COST_PRICE_CHANGES_CREATE)"
          @valid="nextStep('replenishment')"
          @reset="reset">
        </replenishment-form>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion>

      <!-- Logistics section is commented out as it is not required for this page -->
      <!-- <accordion
        custom-class="logistics"
        :option.sync="tabs.logistics"
        :hide-footer="true">
        <template v-slot:header>
          <div class="d-flex align-items-center gap-4">
            <i v-if="formInvalid('logistics') || logisticsCustomError" class="fe fe-alert-circle text-danger mr-1"></i>
            Logistics
            <small v-if="logisticsCustomError" class="text-danger"> - {{logisticsCustomError}}</small>
          </div>
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('logistics')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('logistics')">Edit</a>
        </template>
        <logistics-form
          ref="logistics"
          :value.sync="item"
          :edit.sync="tabs.logistics.edit"
          :uom="uom"
          @valid="nextStep('logistics')"></logistics-form>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion> -->

      <accordion
        custom-class="nutrition"
        :option.sync="tabs.nutrition"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('nutrition')" class="fe fe-alert-circle text-danger mr-1"></i> Nutritional information
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('nutrition') && !isPackable"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('nutrition')">Edit</a>
        </template>
        <nutrition-form
          ref="nutrition"
          :value.sync="item"
          :edit.sync="tabs.nutrition.edit"
          :uom="uom"
          @valid="nextStep('nutrition')"></nutrition-form>
      </accordion>

      <accordion
        custom-class="storage"
        :option.sync="tabs.storagePacking"
        :hide-footer="true">
        <template v-slot:header>
          <i v-if="formInvalid('storagePacking')" class="fe fe-alert-circle text-danger mr-1"></i> Storage and Packing
        </template>
        <template v-slot:card-options>
          <a
            v-if="isEditable('storagePacking')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('storagePacking')">Edit</a>
        </template>
        <storage-packing-form
          ref="storagePacking"
          :value.sync="item"
          :is-packable="isPackable"
          :edit.sync="tabs.storagePacking.edit"
          :uom="uom"
          @valid="nextStep('storagePacking')"></storage-packing-form>
      </accordion>

      <accordion
        custom-class="other"
        :option.sync="tabs.other"
        :hide-footer="true"
        header="Other">
        <template v-slot:card-options>
          <a
            v-if="isEditable('other')"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('other')">Edit</a>
        </template>
        <other-form
          ref="other"
          :value.sync="item"
          :uom="uom"
          :edit.sync="tabs.other.edit"
          @valid="nextStep('other')"></other-form>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion>
      <accordion
        :option.sync="tabs.allergens"
        :hide-footer="true"
        custom-class="allergens"
        header="Allergen Information">
        <allergens-form
          ref="allergens"
          :value.sync="item"
          @valid="nextStep('allergens')"></allergens-form>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion>

      <accordion
        custom-class="compositions"
        :option.sync="tabs.compositions"
        :hide-footer="true"
        header="Compositions">
        <template v-slot:card-options>
          <a
            v-if="isEditable('compositions') && !isPackable"
            href="#"
            class="btn btn-link"
            @click.self.prevent="editForm('compositions')">Edit</a>
        </template>
        <compositions-form
          ref="compositions"
          :value.sync="item"
          :edit.sync="tabs.compositions.edit"
          @valid="nextStep('compositions')"/>
        <template v-slot:footer>
          <button class="btn btn-primary ml-auto">Apply</button>
        </template>
      </accordion>

      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="article"
            :hide-delete="true"
            :isNew="isNew"
            :submitting="submitting"></edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import ArticleStatus from '@/assets/enums/ArticleStatus';
import Accordion from '@/components/Accordion';
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import LogButtonTable from '@/components/LogButtonTable';
import articleEdit from '@/mixins/articleEdit';
import AllergensForm from './forms/AllergensForm';
import CompositionsForm from './forms/CompositionsForm';
import GeneralForm from './forms/GeneralForm';
// import LogisticsForm from './forms/LogisticsForm';
import MaterialTypeForm from './forms/MaterialTypeForm';
import NutritionForm from './forms/NutritionForm';
import OtherForm from './forms/OtherForm';
import ReplenishmentForm from './forms/ReplenishmentForm';
import StoragePackingForm from './forms/StoragePackingForm';

const GENERAL = 'This section contains article information on a unit level.';

export default {
  name: 'EditArticle',
  components: {
    Accordion,
    EditFooter,
    EditHeader,
    GeneralForm,
    LogButtonTable,
    // LogisticsForm,
    MaterialTypeForm,
    NutritionForm,
    OtherForm,
    ReplenishmentForm,
    StoragePackingForm,
    AllergensForm,
    CompositionsForm,
  },
  mixins: [
    /**
     * The `articleEdit` mixin contains shared functionality between
     * Article and Article Requisition create/edit pages
     * because their behaviour is mostly common.
     *
     * The original functionality on this page is set up as the
     * default behaviour in the articleEdit mixin.
     *
     * If something needs to change on this page or in the mixin,
     * please test the Article Requisition create/edit page too;
     * In all likelihood, it will have to be replicated there.
     */
    articleEdit,
  ],
  data() {
    return {
      GENERAL,

      ArticleStatus,
      item: {
        compositions: [],
        brand: null,
        dependencies: [],
        disableIngredient: false,
        expiry_date_of_excess: '',
        expiry_time_after_packing: null,
        has_seasonality: false,
        how_to_taste_test: '',
        how_it_will_be_used: '',
        ingredient: null,
        is_temperature_controlled: false,
        is_weight_based: true,
        ignore_qty_in_price_calc: false,
        logistics: [],
        material_type: null,
        name: '',
        net_weight: null,
        net_weight_uom: null,
        nutritions: {
          carbohydrates: null,
          energyKCal: null,
          energyKJ: null,
          fat: null,
          fibre: null,
          protein: null,
          salt: null,
          saturates: null,
          sugarCarbohydrates: null,
          weight: 100,
        },
        production_lead_time: 0,
        qty_per_net_weight: null,
        qty_per_net_weight_uom: null,
        rank: '',
        replenishments: [],
        season_end_day: '',
        season_end_month: '',
        season_start_day: '',
        season_start_month: '',
        shelf_life_expiration_date: null,
        status: 'draft',
        storage_temperature_after_packing: null,
        storage_temperature_before_packing: null,
        allergens: [],
        is_import: false,
        countries: [],
        assign_bin_type: 'default',
      },
      withParams: [
        'materialType',
        'ingredient',
        'brand',
        'qtyPerNetWeightUom',
        'replenishments',
        'replenishments.pendingCostPriceChanges',
        'logistics',
        'netWeightUom',
        'nutritions',
        'dependencies.allergens',
        'allergens',
        'dependencies.countries',
        'countries',
        'compositions',
        'packagingArticle',
        'packagingMaterial',
        'gnfrType',
      ],
    };
  },
  computed: {
    route() {
      return `/articles/${this.item.id}`;
    },
    /**
     * calculate gnfr:
     * true if -
     * 1. new and not duplicate article
     * 2. edit of gnfr article
     */
    isGNFR() {
      if (this.isNew) {
        return !this.$route.query.duplicate;
      }
      return this.item?.material_type?.name === 'gnfr';
    },
  },
  watch: {
    // handles gnfr for edit article
    isGNFR(newValue) {
      newValue && (this.tabs.nutrition.display = false);
    },
  },
  mounted() {
    // handles gnfr for new article
    this.isGNFR && (this.tabs.nutrition.display = false);
  },
  created() {
    this.disableIngredient = !!this.$route.query.duplicate;
  },
};
</script>

<style scoped>
.side-menu li.list-group-item {
  @apply cursor-pointer;
}
</style>
